@import "normalize.scss";
@import "~semantic-ui-css/semantic.min.css";
@import '~bootstrap/dist/css/bootstrap.min.css';



.ui {
  &.container, &.segment, &.table {
    margin: 1em auto;
    max-width: 1500px !important;
  }
}

.title {
  text-transform: capitalize;
}

.ant-table {
  margin: 1rem auto
}

h1 {
  margin: 0;
}

img {
  max-width: 100%;
  max-height: 70vh;
}